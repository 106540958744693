import React from 'react'

export default function Footer() {
  return (
   <footer className='bg-black py-8 text-center text-white'>
<div className="container  mx-auto">
  Copyright $copy; 2022 , All right reserved 
</div>
   </footer>
  )
}
